<template>
    <div class="login-container d-flex align-items-center">
        <div class="form-login">
            <form @submit.prevent="forgetPassword()">
                <h3 class="fw-bolder text-black">Forgot Password ?</h3>
                <div class="textBox-width mb-5">
                    <small class="text-muted">Don't worry it happens! Enter the Email address associated with your account.</small>
                </div>
                <div class="textBox-width">
                    <span class="p-float-label">
                        <InputText
                            id="email"
                            type="text"
                            class="p-inputtext w-100"
                            v-model="v$.email.$model"
                            @input="onEmailInput"
                        />
                        <label for="email">User Email ID</label>
                    </span>
                    <span
                        v-if="v$.email.$error && (v$.email.required && v$.email.required.$invalid)"
                        class="text-danger">{{messages.registrationValidation.emailRequired}}
                    </span>
                    <span
                        v-else-if="v$.email.$error && (v$.email.email && v$.email.email.$invalid)"
                        class="text-danger">{{messages.registrationValidation.emailInvalid}}
                    </span>
                </div>
                <p v-if="errMessage" class="text-danger">{{ errMessage }}</p>
                <Button
                    class="lavender-purple-background justify-content-center textBox-width mt-4"
                    type="submit"
                    :disabled="loading"
                >
                    <span class="size18 fw-normal">SUBMIT</span>
                </Button>
            </form>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { AuthService } from '../../apis';
import { messages } from '../constants/index';
export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'ForgetPassword',
    components: {
        InputText,
        Button
    },
    data() {
        return {
            email: '',
            errMessage: '',
            loading: false,
            messages
        };
    },
    validations() {
        return {
            email: { required, email }
        };
    },

    methods: {
        async forgetPassword() {
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                return;
            }
            this.loading = true;
            this.email = this.email.trim().toLowerCase();
            AuthService.forgetPassword(this.email).then(
                () => this.$router.push({ name: 'PasswordRecovery' }),
                err => {
                    this.loading = false;
                    switch (err.code) {
                    case 'auth/invalid-email':
                        this.errMessage = messages.forgotPasswordValidation.wrongEmail;
                        break;
                    case 'auth/user-not-found':
                        this.errMessage = messages.forgotPasswordValidation.emailNotExist;
                        break;
                    default:
                        this.errMessage = messages.forgotPasswordValidation.errorOccurred;
                        break;
                    }
                }
            );
        },
        onEmailInput(e) {
            this.errMessage = '';
            this.email = e.target.value = e.target.value.trim().toLowerCase();
        }
    }
};
</script>

<style scoped></style>
